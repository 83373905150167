import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import LikesModal from '../Likes/Modal';
import Lottie from 'react-lottie';
import animationData from '../../../../../public/lotties/clap-animation.json';

const LikeButton = ({currentUserId, likableId, initialLikeCount, initialLikeId, likableType, setArticleLikeId, setArticleLikeCount, containerType}) => {

  const [likeId, setLikeId] = useState(null);
  const [likeCount, setLikeCount] = useState(0);
  const [triggerClapAnimation, setTriggerClapAnimation] = useState(likeId ? true : false);
  const [isStopped, setIsStopped] = useState(true);

  useEffect(() => {
    setLikeId(initialLikeId)
    setLikeCount(initialLikeCount)
  }, [initialLikeId]);

  useEffect(() => {
    $(document).foundation(); // Reinitialize tooltips
  }, [likeId]); // Trigger when `likeId` changes


  const renderClapIcon = () => {
    let image;
    if (likeId) {
      return renderClapAnimation();
    } else {
      image = 'https://public.cureus.com/article-show-redesign/animated-unclapped-new.png';
    }
    return (
      <img
        className="has-tip tip-top icon-size"
        onClick={(event) => {
          const tooltipId = $(event.currentTarget).attr('aria-describedby');
          if (tooltipId) {
            $(`#${tooltipId}`).remove()
          }
          handleClap();
        }}
        src={image}
        data-tooltip=""
        title="Clap"
      />
    )
  }

  const handleClap = () => {
    if (currentUserId) {
      if (likableId && !likeId) {
        setIsStopped(false);
      } else {
        setIsStopped(true);
      }
      handleCreateLikeAndUnlike();
    } else {
      openSignInModal();
    }
  }

  const handleCreateLikeAndUnlike = () => {
    let request = likeId ? 'DELETE' : 'POST';

    $.ajax({
      type: request,
      url: handleLikeUrl(),
      data: {likable_type: likableType, likable_id: likableId},
      success: function(res) {
        setLikeId(res.likeId);
        setLikeCount(res.likeCount);
        setTriggerClapAnimation(!triggerClapAnimation);
        if(likableType === 'Article') {
          setArticleLikeCount(res.likeCount);
          setArticleLikeId(res.likeId);
        }
      }
    });
  }

  const handleLikeUrl = () => {
    if (likeId) {
      return `/likes/${likeId}`
    } else {
      return "/likes"
    }
  }


  const openWhoLikedModal = () => {
    Modal.open({
      children: <LikesModal
        likableId={likableId}
        likableType={likableType}
      />
    });
  }

  const openSignInModal = () => {
    $('#sign-in-modal').foundation('reveal', 'open');
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const renderClapAnimation = () => {
    return (
      <>
        <div
          className="like-box-hover"
          onClick={handleClap}
        />
        <Lottie
          className='has-tip tip-top icon-size'
          options={defaultOptions}
          height={30}
          width={30}
          isStopped={isStopped}
          style={{margin: '0'}}
        />
      </>
    );
  }

  const likeStyling = () => {
    let cssClass = 'like-icon-container ';
    if (containerType === 'featureBar') {
      cssClass += 'feature-container ';
    } else if (containerType === 'topSection') {
      cssClass += 'top-section-container ';
    } else if (containerType === 'shareBar') {
      cssClass += 'sharebar-container ';
      if(!likeId && likeCount === 0) {
        cssClass += 'default-sharebar-container-size ';
      }
    }

    if(likeId) {
      if (containerType === 'featureBar') {
        return cssClass + 'liked feature-bar-liked';
      } else if (containerType === 'shareBar') {
        return cssClass + 'liked share-bar-liked';
      }
      return cssClass + 'liked';
    } else if (likeCount === 0) {
      return cssClass + 'like-center';
    }
    return cssClass;
  }

  const renderCount = () => {
    if(likeCount > 0) {
      let formatter = Intl.NumberFormat('en', { notation: 'compact' });
      return formatter.format(likeCount);
    }
    return;
  }

  return (
    <div className={likeStyling()}>
      {renderClapIcon()}
      <p onClick={openWhoLikedModal} className='has-tip tip-top' data-tooltip="" title="View claps">{renderCount()}</p>
    </div>
  )
}

export default LikeButton;