
var createReactClass = require('create-react-class');
import React from 'react';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';
import { Cureus } from '../../../Cureus';
import Modal from '../../Modal';
import NewModal from './NewModal';
import EditModal from './EditModal';
import Row from './Row';
import Sortable from '../../Sortable';



const Main = createReactClass({
  getInitialState: function() {
    return({
      showRequirementMessage: this.props.showRequirementMessage,
      userships: this.props.userships,
      editing: [],
      searchEmail: '',
      isAuthorships: this.props.isAuthorships,
      loading: false,
      autoFocus: this.props.autoFocus
    })
  },

  componentDidMount: function() {
    $('.authorships-table').keydown(function(e){
      if(e.keyCode == 13) {
        e.preventDefault();
        return false;
      }
    });
    this.checkForAffiliationErrors();
  },

  componentDidUpdate: function() {
    Cureus.SplitButton.init();
    this.checkForAffiliationErrors();
  },

  checkForAffiliationErrors: function() {
    if (this.props.isAuthorships) {
      let save = document.getElementById("save-now")
      let saveAndContinue = document.getElementById("save-and-continue")
      let errorDiv = document.getElementById("error-message")
      var errors = false;
      let userships = this.state.userships;
      for (let i = 0; i < userships.length; i++) {
        let affiliations = userships[i].article_affiliations
        for (let j = 0; j < affiliations.length; j++) {
          let affiliation = affiliations[j]
          if ((affiliation.invalidInstitutionName && !affiliation.validInstitution) || !affiliation.city || !affiliation.country || !affiliation.institutionName || affiliation.institutionName == 'Not Selected' || !affiliation.departmentName || affiliation.departmentName == 'Not Selected' || (affiliation.invalidDepartmentName && !affiliation.validDepartment)) {
            errors = true;
            break;
          }
        }
        if (errors) {
          break;
        }
      }
      if (errors) {
        errorDiv.innerHTML = "Please correct the errors before proceeding.";
        save.classList.add("disabled")
        saveAndContinue.classList.add("disabled")
      } else if(errorDiv) {
        errorDiv.innerHTML = "";
        save.classList.remove("disabled")
        saveAndContinue.classList.remove("disabled")
      }
    }
  },

  updateUserships: function(userships) {
    this.setState({userships: userships, loading: false})
  },

  updateUsership: function(idx, usership) {
    var userships = this.state.userships;
    userships[idx] = usership
    this.setState({userships: userships})
  },

  clearEmailField: function() {
    this.setState({searchEmail: ''});
  },

  handleLoading: function() {
    this.setState({loading: true})
  },

  handleEdit: function(userId, usership) {
    if (this.props.isAuthorships) {
      var editing = this.state.editing;
      editing.push(usership.id);
      this.setState({editing: editing})
    } else {
      Modal.open({
        children: <EditModal
          personId={ userId }
          usership={usership}
          specialtyOptions={ this.props.specialtyOptions }
          countryOptions={ this.props.countryOptions }
          affiliationNameQueryUrl={ this.props.affiliationNameQueryUrl }
          deptAutocompletePath={ this.props.deptAutocompletePath }
          isAuthorships={this.props.isAuthorships}
          updateUserships={this.updateUserships}
          endpointUrl={this.props.endpointUrl}
          defaultParams={this.props.defaultParams}
        />
      });
    }
  },

  handleAuthorshipCancel: function(usershipId) {
    var editing = this.state.editing;
    var newEditing = []
    for (let i = 0; i < editing.length; i++) {
      if (editing[i] != usershipId) {
        newEditing.push(editing[i])
      }
    }
    this.setState({editing: newEditing})
  },

  handleSearch: function() {
    Modal.open({
      children: <NewModal
        isChannel={this.props.isChannel}
        fetchChannelReviewerData={this.props.fetchChannelReviewerData}
        fetchListEmailData={this.props.fetchListEmailData}
        specialtyOptions={ this.props.specialtyOptions }
        qualificationOptions= { this.props.qualificationOptions }
        countryOptions={ this.props.countryOptions }
        affiliationNameQueryUrl={ this.props.affiliationNameQueryUrl }
        deptAutocompletePath={ this.props.deptAutocompletePath }
        searchEmail={ this.state.searchEmail }
        endpointUrl={this.props.endpointUrl}
        updateUserships={this.updateUserships}
        isAuthorships={this.props.isAuthorships}
        defaultParams={this.props.defaultParams}
        clearEmailField={this.clearEmailField}
        isCollection={this.props.defaultParams.authorable_type === 'Collection'}
      />
    });
  },

  updateSearchEmail: function(e) {
    this.setState({searchEmail: e.target.value.trim()});
  },

  handleKeyPress: function(e) {
    if(e.key === 'Enter') {
      this.handleSearch();
      e.preventDefault();
    }
  },

  handleSort: function(e, sortable, component) {
    var self = this;
    if (this.props.isAuthorships) {
      var position = e.newIndex + 1;
      var defaultParams = {authorable_type: self.props.defaultParams["authorable_type"], authorable_id: self.props.defaultParams['authorable_id']}
      self.setState({loading: true},
        UserToolUtils.putUpdatePosition({
          usership: component.props.usership,
          position: position,
          defaultParams: defaultParams,
          endpointUrl: self.props.endpointUrl,
          success: self.updateUserships
        })
      )
    }
  },

  collapseRow: function(usershipId) {
    var newEditing = [];
    var editing = this.state.editing;
    for (let i = 0; i < editing.length; i++) {
      if (editing[i] != usershipId) {
        newEditing.push(editing[i])
      }
    }
    this.setState({editing: newEditing})
  },

  renderUsers: function() {
    var self = this;
    var userRows = this.state.userships.map(function(usership, idx) {
      var editing = self.props.isAuthorships && self.state.editing.includes(usership.id)
      return <Row
        usership={usership}
        onEdit={self.handleEdit}
        key={usership.id}
        idx={idx}
        updateUserships={self.updateUserships}
        updateUsership={self.updateUsership}
        collapseRow={self.collapseRow}
        endpointUrl={self.props.endpointUrl}
        articleId={self.props.articleId}
        submitterId={self.props.submitterId}
        isAuthorships={self.props.isAuthorships}
        isChannel={self.props.isChannel}
        defaultParams={self.props.defaultParams}
        canAdmin={self.props.canAdmin}
        isEditor={self.props.isEditor}
        handleLoading={self.handleLoading}
        userships={self.state.userships}
        current_user={self.props.current_user}
        specialtyOptions={ self.props.specialtyOptions }
        countryOptions={ self.props.countryOptions }
        affiliationNameQueryUrl={ self.props.affiliationNameQueryUrl }
        deptAutocompletePath={ self.props.deptAutocompletePath }
        editing={ editing }
        handleAuthorshipCancel={ self.handleAuthorshipCancel }
        pastEditorCheck={self.props.pastEditorCheck}
        authorshipsRemovable={self.props.authorshipsRemovable}
        isCollection={self.props.defaultParams.authorable_type === 'Collection'}
      />
    });
    if (this.props.isAuthorships) {
      if (this.state.userships.length) {
          return(
            <Sortable tagName='tbody' handleSort={ self.handleSort }>
              {userRows}
            </Sortable>
          )
      } else {
        return(
          <tbody>
            <tr><td colSpan={6}>You don't have any authors yet.</td></tr>
          </tbody>
        )
      }
    } else {
      for(var i = 0; i < (self.props.requiredCount - self.state.userships.length); i++) {
        userRows.push((<tr className='empty-row'><td></td><td></td><td></td><td></td></tr>))
      }
      return <tbody>{userRows}</tbody>;
    }
  },

  toggleNextButton: function() {
    var self = this;
    if (this.props.requiredCount && !this.props.isAuthorships) {
      var nextButton = document.querySelectorAll("[data-button-type='next']");
      [].forEach.call(nextButton, function(el) {
        if (self.state.userships.length < self.props.requiredCount) {
          el.setAttribute("disabled", true)
        } else {
          el.removeAttribute("disabled")
        }
      })
    }
  },

  reviewerRequiredMessage: function() {
    if (!this.props.isAuthorships && !this.props.isChannel){
      if (this.props.requiredCount > this.state.userships.length) {
        return <div className='reviewer-tool-requirement-satisfied'>You need { this.props.requiredCount - this.state.userships.length } more reviewer(s).</div>
      } else {
        return  <div className='reviewer-tool-requirement-satisfied'>Excellent! You've met the minimum requirement, but don't stop now! Improve your chances of efficient and timely review by inviting more reviewers.</div>
      }
    }
  },

  renderMaxAuthors: function() {
    let message;
    if (this.reachedAuthorLimit() && !this.props.isVerification) {
      if(this.props.defaultParams.authorable_type === 'Collection') {
        message = "You cannot add more than 5 co-guest editors to your collection"
      } else {
        message = "You have exceeded the author limit for free articles. This article will not be eligible for free publication if submitted with the current number of authors"
      }
      return (
        <h6 style={{color: '#CA3145'}}>{message}</h6>
      )
    }
  },

  renderSupportCta: function() {
    if (this.props.pastEditorCheck && !this.props.canAdmin && !this.props.isEditor) {
      if (this.props.defaultParams.authorable_type === 'Collection') {
        <p style={{color: 'red'}}>Please contact 
          <a style={{color: 'red'}} href="mailto:support@cureus.com">&nbsp;support@cureus.com</a> if you wish to change an editor's email address.
        </p>
      } else {
        return (
          <p style={{color: 'red'}}>Please contact 
            <a style={{color: 'red'}} href="mailto:support@cureus.com">&nbsp;support@cureus.com</a> if you wish to change an author's email address.
          </p>
        )
      }
    }
  },

  setInstructionsCopy: function() {
    let userType;
    if(this.props.defaultParams.authorable_type === 'Collection') {
      return <p>Choose the corresponding editor and drag and drop the rows to set the editor order. The guest editor list will be displayed on your collection page.</p>
    } else {
      userType = 'author'
      return <p>Choose the corresponding {userType} and drag and drop the rows to set the {userType} order.</p>
    }

  },

  renderInstructions: function() {
    if (this.props.isAuthorships && !this.props.isVerification) {
      if (this.props.defaultParams.authorable_type === 'Collection') {
        return (
          <div>
            {this.renderSupportCta()}
            {this.setInstructionsCopy()}
          </div>
        )
      } else {
        return (
          <div>
            {this.renderSupportCta()}
            <h6>Author List Instructions:</h6>
            {this.setInstructionsCopy()}
          </div>
        )
      }
    } else {
      return;
    }
  },
  
  renderReviewsClosed: function() {
    if (!this.props.isAuthorships && !this.props.isChannel && ['approved','editor_approval', 'published'].includes(this.props.articleState)) {
      return <h6 style={{color: '#CA3145'}}>The peer review period has ended.</h6>
    }
  },

  renderLabel: function() {
    if(!this.props.canAdmin && !this.props.isEditor && this.props.pastEditorCheck) {
      return;
    }
    if(this.props.isVerification) {return;}
    if (this.props.isAuthorships) {
      let userType;
      if(this.props.defaultParams.authorable_type === 'Collection') {
        return (
          <div>
            <label htmlFor='email-search'>Enter co-editor email address and click the Search button.</label>
          </div>
        )
      } else {
        userType = 'Author'
        return (
          <div>
            <label htmlFor='email-search'>{userType} email address</label>
          </div>
        )
      }
    } else if (this.props.isChannel) {
      return (
        <div/>
      )
    } else {
      return (
        <label htmlFor='email-search'>Reviewer email address</label>
      )
    }
  },

  reachedAuthorLimit: function() {
    if(this.props.channelArticle || this.props.competitionArticle) {
      return false;
    }
    if (this.props.articleType == 'OriginalArticle') {
      return this.props.isAuthorships && this.state.userships.length > 10
    }
    let unlimitedAuthors = ['Abstract', 'Poster', 'ReviewArticle']
    return this.props.isAuthorships && this.state.userships.length > 5 && !unlimitedAuthors.includes(this.props.articleType)
  },

  handleDisable: function() {
    if(this.props.defaultParams.authorable_type === 'Collection' && this.reachedAuthorLimit()) {
      return 'disabled'
    }
    if (!this.props.isAuthorships && !this.props.isChannel && ['in_rereview', 'approved','editor_approval', 'published'].includes(this.props.articleState)) {
      return 'disabled'
    }
    if (!this.props.canAdmin && !this.props.isEditor && this.props.pastEditorCheck) {
      return 'disabled'
    }
  },

  renderTableHeader: function() {
    if (this.props.defaultParams.authorable_type === 'Collection') {
      return <th>Editor Details</th>
    } else {
      return <th>Author Details</th>
    }
  },

  renderTable: function() {
    if (this.props.isChannelReviewer || this.props.isEmailList) {
      return
    }
    if(this.props.isAuthorships) {
      return (
        <table style={{width: "100%"}} className={'table-wrapper authorships-table'}>
          <thead>
            <tr>
              <th className="table-draggable"></th>
              <th className="small centered">Position</th>
              <th className="tiny"><i className='far fa-envelope'></i></th>
              {this.renderTableHeader()}
              <th></th>
            </tr>
          </thead>
          { this.renderUsers()}
        </table>
      )
    } else if(this.props.isChannel) {
      return (
        <table style={{width: "100%"}} className='table-wrapper channel'>
          <thead>
            <tr>
              <th style={{width: '200px'}}>Name</th>
              <th style={{width: '265px'}}>Email</th>
              <th>Affiliation</th>
              <th style={{width: '100px'}}>Receive all emails</th>
              <th style={{width: '150px'}}></th>
            </tr>
          </thead>
          { this.renderUsers()}
        </table>
      )
    } else {
      return (
        <table style={{width: "100%"}}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Affiliation</th>
              <th></th>
            </tr>
          </thead>
          { this.renderUsers()}
        </table>
      )
    }
  },
  
  renderSearch: function() {
    if(!this.props.canAdmin && !this.props.isEditor && this.props.pastEditorCheck) {
      return;
    }
    if(!this.props.isVerification) {
      var self = this;
      return (
        <React.Fragment>
          <input disabled={this.handleDisable()} type='text' style={{width: 320, display: "inline-block", marginRight: 8}} placeholder='example@email.com' autoFocus={self.state.autoFocus} onChange={ self.updateSearchEmail } onKeyPress={ self.handleKeyPress } tabIndex='1' value={self.state.searchEmail} className={self.props.isChannel ? 'new-focus' : "" }/>
                  <a className={'button secondary ' + this.handleDisable()} onClick={ self.handleSearch }>Search</a>
        </React.Fragment>
      )
    }
  },

  render: function() {
    var self = this;
    self.toggleNextButton();
    return (
      <div id="author-tool">
        {
          self.state.loading && (<div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
          </div>)
        }
        {this.renderReviewsClosed()}
        {this.renderLabel()}
        {this.renderSearch()}
        {this.renderMaxAuthors()}
        {this.renderInstructions()}
        {this.renderTable()}
        { this.reviewerRequiredMessage() }
      </div>
    )
  }
});

export default Main;