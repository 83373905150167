var createReactClass = require('create-react-class');
import React from 'react';
import DepartmentAutoSuggester from './DepartmentAutoSuggester';
import InstitutionAutoSuggester from './InstitutionAutoSuggester';

const Form = createReactClass({
  blankState: function () {
    return {
      specialty_name: null,
      qualification_title: null,
      first_name: null,
      middle_initial: null,
      last_name: null,
      institution_name: null,
      department_name: null,
      city: null,
      affiliation_country: null
    };
  },
  
  getInitialState: function (){
    if(this.props.resource.user){
      return this.props.resource.user;
    }else {
      return this.blankState();
    }
  },

  getDefaultProps: function (){
    return {
      resource: {user: null},
      title: "Edit details",
      enableSearch: false,
      errors: {}
    }
  },

  componentWillReceiveProps: function (newProps){
    var new_state;
    new_state = {}
    if (newProps.didSubmit) {
      new_state = this.state
    }
    else if(newProps.resource.user && newProps.resource.user.id){
      new_state = newProps.resource.user;
    }else{
      // Clear the form if we just did a search and nothing came back
      if(newProps.didSearch) { new_state = this.blankState(); }
    }
    new_state.focusOn = newProps.focusOn;
    this.setState(new_state)
  },

  componentDidUpdate: function (){
    switch(this.state.focusOn){
      case "firstName":
        this.focusOnFirstName();
        break;
      case "submit":
        this.focusOnSubmit();
        break;
      default:
        return;
    }
    this.setState({focusOn: null});
  },

  focusOnFirstName: function (){
    if(!this.props.resource.editable) { return }
    $(this.refs.firstName).focus();
  },

  focusOnSubmit: function (){
    $(this.refs.submit).focus();
  },

  handleChange: function (evt){
    var field_name, new_state;

    new_state = {}
    new_state[evt.target.name] = evt.target.value;
    this.setState(new_state);
  },

  handleSearchKeypress: function (evt){
    if(evt.keyCode == 13) { //enter key
      evt.preventDefault();
      this.props.onSearch(this.refs.email.value);
    }
  },

  handleSearch: function (evt){
    this.props.onSearch(this.refs.email.value);
  },

  handleSubmit: function (evt){
    var params, email_val, first_name_val, last_name_val, middle_initial_val, department_name_val, institution_name_val, country, city, $form, specialty_name_val, qualification_title_val;

    evt.preventDefault();

    if(this.props.resource.editable){
      $form = $(this.refs.form);

      email_val = $form.find("[name='email']")[0].value;
      first_name_val = $form.find("[name='first_name']")[0].value;
      middle_initial_val = $form.find("[name='middle_initial']")[0].value;
      last_name_val = $form.find("[name='last_name']")[0].value;
      specialty_name_val = $form.find("[name='specialty_name']")[0].value;
      
      params = {
        user: {
          email: email_val.length == 0 ? this.props.resource.user.email : email_val,
          first_name: first_name_val.length == 0 ? this.props.resource.user.first_name : first_name_val,
          middle_initial: middle_initial_val.length == 0 ? this.props.resource.user.middle_initial : middle_initial_val,
          last_name: last_name_val.length == 0 ? this.props.resource.user.last_name : last_name_val,
          specialty_name: specialty_name_val.length == 0 ? this.props.resource.user.specialty_name : specialty_name_val,
          id: this.props.resource.user.id || null
        }
      }

      if (this.props.isChannel) {
        const qualification_title_val = $form.find("[name='qualification_title']")[0].value;
        params.user.qualification_title = qualification_title_val.length == 0 ? this.props.resource.user.qualification_title : qualification_title_val;
      }

      if (!this.props.hideInstitutionFields) {
        department_name_val = $form.find("[name='department_name']")[0].value;
        institution_name_val = $form.find("[name='institution_name']")[0].value;
        city = $form.find("[name='city']")[0].value;
        country = $form.find("[name='country']")[0].value;

        params.user.department_name =  department_name_val.length == 0 ? this.props.resource.user.department_name : department_name_val,
        params.user.institution_name = institution_name_val.length == 0 ? this.props.resource.user.institution_name : institution_name_val
        params.user.city = city.length == 0 ? this.props.resource.user.city : city
        params.user.country = country.length == 0 ? this.props.resource.user.country : country
      }

    }else{
      params = { user: this.props.resource.user };
    }

    this.props.onSubmit(params);

  },

  hasEmailErrors: function (){
    return (this.props.errors && this.props.errors.email)
  },

  handleSpecialtyChanged: function (evt){
    this.setState({specialty_name: evt});
  },

  handleQualificationChanged: function (evt) {
    this.setState({qualification_title: evt});
  },

  handleCountryChanged: function (evt){
    this.setState({affiliation_country: evt});
  },
  isAddButtonDisabled: function (){
    if(this.props.resource.user) { return false; }
    return 'disabled';
  },

  isEditable: function (){
    this.props.resource.editable || !this.props.resource.user.id
  },

  renderCheckingForUser: function (){
    if(!this.props.showCheckingForUser) { return null; }
    return (
      <span className='checking-user-account'>
         Checking for existing account.
      </span>
    )
  },

  renderCheckmark: function (){
    if(!this.props.resource.user || !this.props.resource.user.id) { return null; }
    return (
      <span className='checkmark'>
        <i className="fa fa-check"></i>
      </span>
    )
  },

  renderSearchFunction: function (){
    if(!this.props.enableSearch) {
      return ([
        <div className="large-2 small-4 columns"> </div>,
        <div className="large-1 small-0 columns"> </div>
      ])
    }
    return ([
      <div key="user_picker_search_button" className="large-2 small-4 columns">
        <a className="postfix button primary search-user" onClick={this.handleSearch}>
          Search
        </a>
      </div>,

      <div key="user_picker_search_spinner" className="large-1 small-0 columns">
        { this.renderSpinner() }
        { this.renderCheckmark() }
      </div>
    ])
  },

  renderSpinner: function (){
    if(!this.props.showSpinner) { return null; }
    return (
      <span className='spinner'>
        <i className="fa fa-spinner"></i>
      </span>
    )
  },

  renderUserExistsLabelForAuthorship: function() {
    let self = this;
    let user = this.props.resource.user;
    let userType = this.props.isCollection ? 'editor' : 'co-author'
    let buttonText = this.props.submitText;
    
    if (!user) {
      return
    }
    if (!user.is_comment_or_digest && user.is_confirmed) {
      if (this.props.isChannel) {
        return (<p>Look who we found! {user.first_name} already has a Cureus account. Click the {buttonText} button to submit {user.first_name} for journal review. All current Channel Admins will be notified via email when the journal's evaluation is complete.</p>)
      } else {
        return (<p>Look who we found! {user.first_name} already has a Cureus account. You can edit their name, affiliation name and location fields by clicking the Edit button next to their name in the {this.props.isCollection ? 'editor' : 'author'} list.</p>)
      }
      
    }
    if (user.registered) {
      if (this.props.isChannel) {
        return (<p>We found this email in our system, but they haven’t confirmed their account yet. Please edit all fields for accuracy and formatting consistency. Click the {buttonText} button to submit {user.first_name} to your internal list of peer reviewers!</p>)
      } else {
        return (<p>This email is in our system, but your {userType} hasn't confirmed their account. Please edit all fields for accuracy and formatting consistency.</p>)
      }
    } else {
      if (this.props.isChannel) {
        return (<p>We didn't find an account associated with this email address. Please complete all fields and click the {buttonText} button to submit {user.first_name} for journal review. All current Channel Admins will be notified via email when the journal's evaluation is complete.</p>)
      } else {
        return (<p>We didn't find an account associated with this email address. Please complete all fields and we will invite your {userType} to confirm.</p>)
      }
    }
  },

  renderUserExistsLabel: function (){
    // TODO: JB this thing is so damn messy
    var userType;
    if (this.props.title === 'Add an Author' || this.props.title === 'Edit details' || this.props.title === 'Edit Author Details') {
      userType = 'co-author'
    } else {
      userType = 'reviewer'
    }
    if (this.props.isAuthorship) {
      return this.renderUserExistsLabelForAuthorship();
    }
    if(this.props.doNotShowUserExistsLabel) {
      if(this.props.resource.user.is_confirmed){
        var msg = this.props.title === 'Author Details' ? 
          <p>This author has confirmed their account and you can not make changes.
            <br/>
            Please contact your coauthor if you find any errors in their details.</p> :
          <p>You can't edit this person's details. Their account has already been confirmed.</p>
      }else{
        var msg = this.props.title === 'Edit Author Details' ?
          <p>Your coauthor has not confirmed their account. We recommend reminding them to complete their profile prior to peer review. Alternatively, you may edit their details below.</p> :
          <p>We found an account associated with this email address, but it has not been confirmed. Please update all fields and we will invite your {userType} to confirm.</p> //ADD EDIT
      }
      return ( msg )
    }
    if(this.props.resource.user && this.props.resource.user.id){
      if(this.props.resource.user.is_confirmed){
        var msg =
          <p>Look who we found! {this.props.resource.user.first_name} already has a Cureus account. <strong>Note:</strong> Each Cureus user is responsible for his or her profile details.</p>
      }else{
        var msg =
          <p>We found an account associated with this email address, but it has not been confirmed. Please update all fields and we will invite your {userType} to confirm.</p> //ADD EDIT
      }
      return ( msg )
    }else{
      return (
        <p>We didn't find an account associated with this email address. xxxxxPlease update all fields and we will invite your {userType} to confirm.</p>
      )
    }
  },

  renderEmailField: function (){
    var emailErrors;

    if(!this.props.enableSearch) {
      return (
        <div className="large-9 small-8 columns">
          <input
            type="email"
            name="email"
            ref="email"
            className="user-email"
            id="user-email"
            value={this.props.resource.user.email}
            disabled="disabled"
            autoComplete="off" />
        </div>
      )
    }

    emailErrors = null;
    if(this.hasEmailErrors()) {
      emailErrors = (
        <div className="errors">
          { this.props.errors.email }
        </div>
      )
    }
    return (
      <div className="large-9 small-8 columns">
        <input
          type="email"
          name="email"
          id="user-email"
          ref="email"
          className={"user-email"+(this.hasEmailErrors() ? " error" : "")}
          onBlur={this.handleSearch}
          onKeyDown={this.handleSearchKeypress}
          defaultValue={this.props.email}
          autoComplete="off" />
        { emailErrors }
      </div>
    )
  },

  renderEmailLabel: function (){
    var emailErrors;
    if(!this.props.enableSearch) {
      return (
        <div>
          Email: <a href={ 'mailto:' + (this.props.email || this.props.resource.user.email || this.props.searchEmail) }>{ this.props.email || this.props.resource.user.email || this.props.searchEmail}</a>
        </div>
      )
    }

    emailErrors = null;
    if(this.hasEmailErrors()) {
      emailErrors = (
        <div className="errors">
          { this.props.errors.email }
        </div>
      )
    }
    return (
      <div>
        Email: <a href={ 'mailto:' + (this.props.email || this.props.resource.user.email) }>{ this.props.email || this.props.resource.user.email }</a>
        { emailErrors }
      </div>
    )
  },

  renderFirstNameField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.first_name){
      class_name = "error"
      error_field = ( <div key="user_picker_first_name_errors" className="errors"> {this.props.errors.first_name} </div> )
    }
    if(this.props.resource.editable || !this.props.resource.user.id){
      return ( [<input autoComplete='new-password' key="user_picker_first_name_field" type="text" name="first_name" id="user_first_name" ref="firstName" className={class_name} onChange={this.handleChange} value={this.state.first_name}  />, error_field] )
    }
    return ( <input autoComplete='new-password' key="user_picker_first_name_field" type="text" name="first_name" id="user_first_name" value={this.props.resource.user.first_name} disabled="disabled" /> )
  },

  renderMiddleInitialField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.middle_initial){
      class_name = "error";
      error_field = ( <div key="user_picker_middle_initial_errors" className="errors"> {this.props.errors.middle_initial } </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id) {
      return ( [<input autoComplete='new-password' key="user_picker_middle_initial_field" type="text" name="middle_initial" id="user_mi" ref="middleInitial" className={class_name} onChange={this.handleChange} value={this.state.middle_initial} maxLength="1" />, error_field] )
    }
    return ( <input autoComplete='new-password' key="user_picker_middle_initial_field" type="text" name="middle_initial" id="user_mi" value={this.props.resource.user.middle_initial} disabled="disabled" maxLength="1" /> )
  },

  renderLastNameField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.last_name){
      class_name = "error";
      error_field = ( <div key="user_picker_last_name_errors" className="errors"> {this.props.errors.last_name} </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id){
      return ([<input autoComplete='new-password' key="user_picker_last_name_field" type="text" name="last_name" id="user_last_name" ref="lastName" className={class_name} onChange={this.handleChange} value={this.state.last_name} />, error_field])
    }
    return (<input autoComplete='new-password' key="user_picker_last_name_field" type="text" name="last_name" id="user_last_name" value={this.props.resource.user.last_name} disabled="disabled" />)
  },

  renderInstitutionNameField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.institution_name){
      class_name = "error";
      error_field = ( <div key="user_picker_institution_name_errors" className="errors"> {this.props.errors.institution_name } </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id){
      return (
        [
          (<InstitutionAutoSuggester autosuggestUrl={this.props.affiliationNameQueryUrl} >
            <input type="text" key="user_picker_institution_name_field" name="institution_name" id="user_institution_name" ref="institutionName" className={class_name} onChange={this.handleChange} value={this.state.institution_name} autoComplete='new-password' />
          </InstitutionAutoSuggester>),
        error_field ]
      )
    }
    return (<input type="text" key="user_picker_institution_name_field" name="institution_name" id="user_institution_name" value={this.props.resource.user.institution_name} disabled="disabled" autoComplete='new-password' />)
  },

  renderDepartmentNameField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.department_name){
      class_name = "error";
      error_field = ( <div key="user_picker_department_name_errors" className="errors"> {this.props.errors.department_name } </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id) {
      return (
        [
          (<DepartmentAutoSuggester deptAutocompletePath={this.props.deptAutocompletePath} institutionName={this.state.institution_name}>
          <input key="user_picker_department_name_field" type="text" name="department_name" id="user_department_name" ref="departmentName" className={class_name} onChange={this.handleChange} value={this.state.department_name} disabled={this.handleDisabled()} autoComplete='new-password' />
          </DepartmentAutoSuggester>), 
          error_field
        ]
      )
    }
    return (<input type="text" name="department_name" id="user_department_name" value={this.props.resource.user.department_name} disabled="disabled" autoComplete='new-password' />)
  },

  handleDisabled: function() {
    if (!this.state.institution_name){
      return true;
    }
    return false;
  },

  renderSpecialtyField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.specialty_name){
      class_name = "error";
      error_field = ( <div key="user_picker_specialty_errors" className="errors"> {this.props.errors.specialty_name } </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id) {
      return (
        [ <Select key="user_picker_specialty_field" name="specialty_name" id="user_specialty_name" options={ this.props.specialtyOptions } searchable={ false } className={class_name} onChange={this.handleSpecialtyChanged} value={this.state.specialty_name} />,
          error_field ]
      )
    }
    return (<input autoComplete='new-password' type="text" name="specialty_name" id="user_specialty_name" value={this.props.resource.user.specialty_name} disabled="disabled" />)
  },

  renderQualificationField: function() {
    var class_name, error_field;
    
    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.qualification_titles){
      class_name = "error";
      error_field = ( <div key="user_picker_qualification_errors" className="errors"> {this.props.errors.qualification_titles } </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id) {
      return (
        [ <Select key="user_picker_qualification_field" name="qualification_title" id="user_qualification_title" options={ this.props.qualificationOptions } searchable={ false } className={class_name} onChange={this.handleQualificationChanged} value={this.renderQualificationDefaultValue()} />,
          error_field ]
      )
    }
    return (<input autoComplete='new-password' type="text" name="qualification_title" id="user_qualification_title" value={this.props.resource.user.qualification_titles[0]} disabled="disabled" />)
  },

  renderQualificationDefaultValue: function() {
    if (this.props.resource.editable && this.props.resource.user?.qualification_titles?.length > 0) {
      return this.props.resource.user.qualification_titles[0];
    } else {
      return this.state.qualification_title;
    }
  },

  isNewUser: function() {
    return !this.props.resource.user.id
  },

  renderUserDetailsFields: function (){
    if(!this.props.resource.user) { return null; }
    return (
      <div className="user-details-wrap">
        <div className="form-fields">
          <input type='hidden' value={ this.props.email } name='email'/>
          <div className="row">
            <div className="small-5 columns">
              <label htmlFor="user_first_name">First name</label>
              { this.renderFirstNameField() }
            </div>

            <div className="small-1 columns">
              <label htmlFor="user_mi">MI</label>
              { this.renderMiddleInitialField() }
            </div>

            <div className="small-6 columns">
              <label htmlFor="user_last_name">Last name</label>
              { this.renderLastNameField() }
            </div>
          </div>

          <div className="row">
            <div className="small-10 columns">
              <label htmlFor="user_specialty_name">Specialty</label>
            </div>
          </div>
          <div className="row">
            <div className="small-10 columns">
              { this.renderSpecialtyField() }
            </div>
          </div>
          {this.props.isChannel &&
            <>
              <div className="row">
                <div className="small-10 columns">
                  <label htmlFor="user_qualification_title">Qualification</label>
                </div>
              </div>
              <div className="row">
                <div className="small-10 columns">
                  { this.renderQualificationField() }
                </div>
              </div>
            </>
          }
          { this.renderInstitutionAndDepartmentFields() }
        </div>
      </div>
    )
  },

  renderCity: function() {
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.city){
      class_name = "error";
      error_field = ( <div key="affiliaiton_city_errors" className="errors"> {this.props.errors.city} </div> )
    }
    if(this.props.resource.editable || !this.props.resource.user.id) {
      return ([<input key="user_picker_city_field" type="text" name="city" id="city" ref="affiliationCity" className={class_name} onChange={this.handleChange} value={this.state.city} autoComplete='new-password' />, error_field])
    }
    return (<input type="text" name="city" id="city" value={this.props.resource.user.city} disabled="disabled" autoComplete='new-password' />)
  },

  renderCountry: function() {
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.country){
      class_name = "error";
      error_field = ( <div key="affiliaiton_country_errors" className="errors"> {this.props.errors.country} </div> )
    }

    if(this.props.resource.editable || !this.props.resource.user.id) {
      return ([<Select key="user_picker_country_field"  name="country" id="country" options={this.props.countryOptions} className={class_name} onChange={this.handleCountryChanged} value={this.state.affiliation_country} autoComplete='new-password' />, error_field])

    }
    return (<input type="text" name="country" id="country" value={this.props.resource.user.affiliation_country} disabled="disabled" autoComplete='new-password' />)

  },


  renderInstitutionAndDepartmentFields: function() {
    if (!this.props.hideInstitutionFields) {
      return(
        <div>
          <div className='row'><div className='large-12 columns'><h4>Affiliation Name and Location</h4></div></div>

          <div className="row">
            <div className="small-12 columns">
              <label htmlFor="user_institution_name">Hospital/Academic Institution</label>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              { this.renderInstitutionNameField() }
            </div>
          </div>

          <div className="row">
            <div className="small-12 columns">
              <label htmlFor="user_department_name">Department/Specialization (Med students should list relevant department.)</label>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              { this.renderDepartmentNameField() }
            </div>
          </div>

          <div className="row">
            <div className="small-6 columns">
              <label htmlFor="country">Country</label>
              { this.renderCountry() }
            </div>
            <div className="small-6 columns">
              <label htmlFor="city">City</label>
              { this.renderCity() }
            </div>
          </div>
        </div>
      )
    }
  },

  disableSubmit: function() {
    if (!this.props.resource.user) {
      return "disabled"
    } else if (this.props.submitting) {
      return "disabled"
    } else {
      return false
    }
  },

  renderFormSubmitButtons: function() {
    if (this.props.added && (this.props.resource.user && this.props.resource.user.is_confirmed && !this.props.edit)) {
      if(this.props.dashboardEmail) {
        return (
          <div className="modal-footer">
            <a href="#" className="button secondary cancel-modal">
            Cancel
            </a>
            <button className="button add-user user-picker-form-submit" ref="submit" disabled={this.disableSubmit()} onClick={this.handleSubmit}>
              Update Author
            </button>
        </div>
        )
      }
      return (
        <div className="modal-footer">        
          <button className="button add-user user-picker-form-submit" ref="submit" disabled={this.disableSubmit()} onClick={this.handleSubmit}>
            Done
          </button>
        </div>
      )
    }
    return (
      <div className="modal-footer">
        <a href="#" className="button secondary cancel-modal">
        Cancel
        </a>
        <button className="button add-user user-picker-form-submit" ref="submit" disabled={this.disableSubmit()} onClick={this.handleSubmit}>
          {this.props.submitText}
        </button>
      </div>
    )
  },
  renderFooter: function(){
    if(this.props.resource.user && this.props.resource.user.is_confirmed && this.props.edit){
      var text = this.props.title == 'Author Details' ? "Done" : "Close"
      return (
        <div className="modal-footer">
          <a href="#" className="button secondary cancel-modal">
            {text}
          </a>
        </div>
      );
    }else{
      return this.renderFormSubmitButtons();
    }
  },

  render: function (){
    return (
      <form className="custom user-picker-form" onSubmit={this.handleSubmit} ref='form'>
        <div className="form-fields">
          <div className='row'>
            <div className='small-12 columns'>
              <h4>{this.props.title}</h4>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns status-bar">
              { this.renderCheckingForUser() }
              { this.renderUserExistsLabel() }
              { this.renderEmailLabel() }
            </div>
          </div>
        </div>
        { this.renderUserDetailsFields() }
        { this.renderFooter() }
      </form>
    )
  }
});

export default Form;
