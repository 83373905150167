import React, { useEffect, useState } from 'react';
import { formatChinaPhoneNumber } from '../../utils/formatPhoneNumber';

const PhoneNumber = ({areaCode, phoneNumber, userId, renderButton, renderFlash, renderModalCloseX}) => {
  const [countryCode, setCountryCode] = useState(areaCode)
  const [phoneNumberState, setPhoneNumberState] = useState(phoneNumber)
  const [checked, setChecked] = useState(false);
  const handleUnsuccessfulSubmission = () => {
    $('#phone-number-modal').foundation('reveal', 'close');
    window.Cureus.Flash.alert("Must be a valid phone number");
  }

  // Update modal number state when props change
  useEffect(function onPhoneNumberChange() {
    setPhoneNumberState(formatChinaPhoneNumber(phoneNumber));
    setCountryCode(areaCode);
  }, [phoneNumber]);

  const handlePhoneNumberChange = (event) => {
    const formattedNumber = formatChinaPhoneNumber(event.target.value);
    setPhoneNumberState(formattedNumber);
  }

  const validPhoneNumber = () => {
    return phoneNumberState && phoneNumberState.length === 13;
  }
  
  const onClickCheckBox = () => {
    setPhoneNumberState('');
    setChecked(!checked);
  }

  const enableButton = () => {
    if (checked) {
      return true;
    }
    return validPhoneNumber();
  }

  const sanitizePhoneNumber = () => {
    if (phoneNumberState && phoneNumberState.length > 0) {
      return phoneNumberState.replace(/\D/g,'');
    }
    return "";
  }

  return (
    <div className="reveal-modal medium" id="phone-number-modal" data-reveal>
      <div className="modal-body">
        {renderFlash()}
        {renderModalCloseX()}
        <div className="row">
          <div className="large-12 columns">
            <h4>Phone Number</h4>
            <h6 className="reg">Please enter and save your phone number.</h6>
          </div>
        </div>
        <div className="row">
          <div className="large-6 columns phone-number-container">
            <input
              readOnly={true}
              className="country-code-input"
              name="country-code"
              type="text"
              value={`+${countryCode}`}
              onChange={(e) => {
                setCountryCode(e.target.value)
              }}/>
            <input
              name="phone-number"
              type="tel"
              value={phoneNumberState}
              disabled={checked}
              onChange={(event) => handlePhoneNumberChange(event)}
              maxLength={13}
            />
          </div>
        </div>

        <div className='row'>
          <div className='large-6 columns'>
            <input
              name='phone-number'
              id='phone-checkbox'
              type='checkbox'
              checked={checked}
              onChange={onClickCheckBox}
            />
            <label htmlFor="phone-checkbox">I prefer not to share my phone number.</label>
          </div>
        </div>

        <div className="modal-footer">
          {
            renderButton({
              user: { china_phone: `${sanitizePhoneNumber()}` },
              section: "ChinaPhoneNumberForm",
            }, `/users/${userId}`, handleUnsuccessfulSubmission, enableButton())
          }
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;