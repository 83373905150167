import React from 'react';

const UnacceptableFigureExamples = () => {
  return (
    <React.Fragment>
      <h1>Bad Figure Examples</h1>
      <p>Including figures similar to the following examples may cause your article submission to be deferred or rejected. If your figures look like these figures, please address the issues prior to submitting your articles.</p>
      <div className='small-font space-top-m'>
        <i className='fas fa-exclamation-triangle red space-right-xs'></i>
        <strong>Blurry</strong>
        <img className='space-top-xs' src='https://public.cureus.com/blurry_figure.png'/>
      </div>
      <div className='small-font space-top-m'>
        <i className='fas fa-exclamation-triangle red space-right-xs'></i>
        <strong>Requires Cropping</strong>
        <div>
          <img className='space-top-xs' src='https://public.cureus.com/requires-cropping.png' style={{width: '100%'}}/>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UnacceptableFigureExamples;