var createReactClass = require('create-react-class');
import React from 'react';
import Channel from './Channel';

const Main = createReactClass({
  getInitialState: function() {
    return {
      channels: [
        {image: 'https://public.cureus.com/channels-index/acom_up_up.png', link: '/channels/acom'}, 
        {image: 'https://public.cureus.com/acos_channel.png', link: '/channels/acosgensurg'},
        {image: 'https://public.cureus.com/MaxSim.jpg', link: '/channels/simulation-archives'},
        {image: 'https://public.cureus.com/amredcross_channel.jpg', link: '/channels/redcross'},
        {image: 'https://public.cureus.com/channels-index/jnmc_up_up.png', link: '/channels/jnmc'}, 
        {image: 'https://public.cureus.com/channels-index/NEMAgroup.png', link: '/channels/nema'}, 
        {image: 'https://public.cureus.com/channels-index/SBH-IM.png', link: '/channels/sbh'},
        {image: 'https://public.cureus.com/channels-index/NSU_KPCOM.png', link: '/channels/nsu-flagship'},
        {image: 'https://public.cureus.com/channels-index/VCOM.png', link: '/channels/vcom'},

      ],
      start: 0,
      max: 3
    }
  },

  renderChannels: function() {
    var self = this;
    var end = self.state.max + self.state.start
    var length = self.state.channels.length
    var over;
    if(end > length) {
      over = end - length
      end = length
    }
    var channels = self.state.channels.slice(self.state.start, end)
    if(over) {
      channels = channels.concat(self.state.channels.slice(0, over))
    }
    return channels.map(function(channel) {
      return <Channel image={channel.image} key={channel.image} link={channel.link}/>
    })
  },

  handleNext: function(dir) {
    var start = this.state.start
    var length = this.state.channels.length
    if (dir === 'left') {
      start = start - 3
      if(start < 0) {
        start = length + start
      }
    } else if (dir === 'right'){
      start = start + 3
      if(start > length) {
        start = start - length
      }
    }
    this.setState({start: start})
  },

  render: function(){
    return (
        <React.Fragment>
          <div className="row">
            <div className="large-10 large-offset-1 medium-12 small-12 columns">
                <div className="channel-carousel-container">
                  <h2>Visit Some of Our Current Cureus Channels Below</h2>
                  <p><a href="/about">Cureus</a> is proud to collaborate with over 70 esteemed <a href="/partnerships">institutional channel partners</a> in the publication of medical research! Our partners span the globe, reflecting a diverse and vibrant community dedicated to advancing medical science and education.</p>
                  <p className="link-text">Explore the full list of our channel partners <a href="/channels/all">here</a>.</p>
                  <br/>
                </div>
            </div>
          </div>
          <div className="row">
            <div className="large-10 large-offset-1 medium-12 small-12 columns flex-container">
              <div className="large-1 medium-1 columns show-for-medium-up arrow-flex">
                <div className="channel-carousel-arrow-container">
                  <i className="far fa-arrow-alt-circle-left"  onClick={() => this.handleNext('left')}></i>
                </div>
              </div>
              <div className="medium-10 large-10 small-12 columns">
                <div className="channel-carousel-container">
                  {this.renderChannels()}
                </div>
              </div>
              <div className="large-1 medium-1 columns show-for-medium-up arrow-flex">
                <div className="channel-carousel-arrow-container">
                  <i className="far fa-arrow-alt-circle-right" onClick={() => this.handleNext('right')}></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row show-for-small-only">
          <div className="small-12 columns">
            <div className="channel-carousel-arrow-container">
              <i className="far fa-arrow-alt-circle-left"  onClick={() => this.handleNext('left')}></i>
              <i className="far fa-arrow-alt-circle-right" onClick={() => this.handleNext('right')}></i>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
})

export default Main;