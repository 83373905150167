import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import { fireHubspotEvent } from '../../Cureus/HubspotEventClickTrack';

const ColleagueInvitationModal = ({fromEmail}) => {

  const [title, setTitle] = useState('');
  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [loadedCKEditor, setLoadedCKEditor] = useState(false);

  useEffect(() => {
    if (!loadedCKEditor) {
      new Cureus.CKEditor("invite-message", {
        handle_change: updateMessage,
        editor_type: "static_text",
        ckeditor: { height: 200 },
        placeholder: "Body"
      });
      setLoadedCKEditor(true);
    }
  }, [])

  const updateMessage = () => {
    setMessage(CKEDITOR.instances['invite-message'].getData());
  }

  const queueInvite = () => {
    $.ajax({
      type: 'POST',
      dataType: "JSON",
      url: '/dashboard/invite_colleague_to_collection',
      data: {
        share_email: {from: fromEmail, title: title, subject: subject, message: message, recipients: recipients}
      },
      success: function(res) {
        Modal.close();
        Cureus.Flash.success(res.success);
        fireHubspotEvent('collectionInviteAuthorsSent');
      },
      error: function(res) {
        Cureus.Flash.alert(res.alert);
      }
    });
  }

  return (
    <div id={"invite-colleague-reveal-modal"} className="reveal-modal new-modal invite-colleague-modal middling large-12 columns" data-reveal>
      <div className="modal-body invite-colleague-section">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <div className='invitation-img-container'>
                <img src="https://public.cureus.com/my_dashboard/my_collections/invitation-modal-img.png" />
              </div>
              <h4 className='text-center modal-title'>Invite your colleagues to submit to your collection!</h4>
              <div className="input-container">
                <label htmlFor='title'>Title</label>
                <input id='title' type="text" placeholder="Please enter your title here." onChange={(e) => setTitle(e.target.value)}/>
              </div>
              <div className="input-container">
                <label htmlFor='recipients'>Recipients</label>
                <input id='recipients' type="text" placeholder="Please enter email addresses of the recipients separated by comma." onChange={(e) => setRecipients(e.target.value)}/>
              </div>
              <div className="input-container">
                <label htmlFor="from">From</label>
                <input type="text" value={fromEmail} id="from" disabled/>
              </div>
              <div className="input-container">
                <label htmlFor='subject'>Subject</label>
                <input id='subject' type="text" placeholder="Please enter your subject here." onChange={(e) => setSubject(e.target.value)}/>
              </div>

              <div className="input-container">
                <textarea
                  id='invite-message'
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="button cancel-modal">Cancel</a>
            <button className="button" onClick={queueInvite}>Send</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColleagueInvitationModal;