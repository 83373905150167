import React, { useEffect } from 'react';
import Label from './Label'
import { useState } from 'react';

const QuestionAnswerItem = ({id, question, answer, handleQuestionChange, subtitle, type, minimumResponseLength}) => {
  const [answerState, setAnswerState] = useState('');
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    setAnswerState(answer);
  }, [answer])

  const handleAnswerChange = (e) => {
    const answerValue = e.target.value;
    handleQuestionChange(id, answerValue);
    setAnswerState(answerValue)
    setIsEmpty(answerValue.trim() == "");
  };

  if(type != "CredibleConclusionsQuestion") {
    return (
      <div className='row'>
        <div className="small-12 columns question-container">
          <Label label={question} sublabel={subtitle} />
          <textarea className='questionnaire-answer' value={answerState} onChange={handleAnswerChange}></textarea>
          {isEmpty && (
            <p className='empty-response'>This field is required.</p>
          )}
          {(answerState && answerState.length > 0 && answerState.length < minimumResponseLength) && (
            <p className='empty-response'>Your response must include at least {minimumResponseLength - answerState.length} more characters.</p>
          )}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default QuestionAnswerItem;