import {Cureus} from '../Cureus';
Cureus.HubspotEventClickTrack = function(htmlSelector, eventName, hubspotPropertiesJSON='json') {
  let hubspotProperties;
  let isValidJSON = true;

  try {
    hubspotProperties = JSON.parse(hubspotPropertiesJSON);
  } catch (e) {
    isValidJSON = false;
  }

  if (!isValidJSON) { hubspotProperties = {};}

  document.querySelector(htmlSelector).addEventListener('click', function() {
    fireHubspotEvent(eventName, hubspotProperties);
  });
}

export function fireHubspotEvent(eventName, hubspotProperties={}) {
  _hsq.push([
    'trackCustomBehavioralEvent',
    {
      name: hubspotEventName(eventName),
      properties: hubspotProperties,
    },
  ]);
}

 function hubspotEventName(eventName) {
  let id = document.querySelector("meta[data-hubspot-id]").dataset.hubspotId;
  eventName = `${id}_${eventName.toLowerCase()}`;
  return eventName;
}