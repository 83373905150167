import React, { useState } from 'react';

const UploadProofModal = ({handleUploadPDF}) => {
  const [fileData, setFileData] = useState({})

  return (
    <div className="reveal-modal small upload-proof-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Upload IRB/Ethics Committee Approval or Waiver Documentation</h5>
          </div>
          <hr/>

          <div className="modal-content">
            <div className='modal-form'>
              <p>Only official IRB or ethics committee letters will be accepted. Letters may be uploaded as PDF, JPEG, JPG, or PNG files.</p>
              <input type="file" onChange={(e) => {setFileData(e.target.files[0])}  }/>

              <div className="form-actions text-center">
                <a className='cancel-modal button special'>CANCEL</a>
                <a className='cancel-modal button primary' onClick={() => {handleUploadPDF(fileData)}}>Upload</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadProofModal
